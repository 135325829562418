import React, { Component, useCallback, useEffect, useState } from 'react'
import { Row, Col, Card, Tab, Tabs, Table, Button, Form, ToastContainer, Toast } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import avatar1 from '../../assets/images/user/avatar-1.jpg'
import avatar2 from '../../assets/images/user/avatar-2.jpg'
import avatar3 from '../../assets/images/user/avatar-3.jpg'

import {
    BsEmojiSunglasses,
    BsSun,
    BsPeople,
    BsPersonBoundingBox,
    BsEmojiWink
} from 'react-icons/bs'
import { VisitorCreate } from '../components/visitorCreate'
import { AccessLog } from '../components/accessLog'
import { FindUser } from '../components/findUser'
import { OpenGate } from '../components/openGate'
import { UserCreate } from '../components/userCreate'
import { MultipleUserCreate } from '../components/multipleUserCreate'
import ShowEventProvider from '../Events/showEventProvider'

import faceOK from '../../images/icon-smile.svg'

import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import { UserCreateAdmin } from '../components/userCreateAdmin'

import { AiOutlineUsergroupAdd, AiOutlineForm, AiOutlineMinusCircle } from "react-icons/ai";

function CreateAdminUser() {
    const currentUser = sessionStorage.getItem('role')
    const companyId = sessionStorage.getItem('company')

    const [isLoading, setLoading] = useState(false)

    const [show, setShow] = useState(false);

    if (currentUser === 'default') {
        window.location.href = '/dashboard'
    }
    const auth = sessionStorage.getItem('auth')

    if (auth !== 'true') {
        window.location.href = '/'
    }
    const [photoState, setphotoState] = useState<string>('')

    const handleResponse = useCallback((message: string) => {
        setphotoState(message)
    }, [])

    useEffect(() => {
        const params = new URLSearchParams(window.location.search) // id=123
        if (params.get('fixBrowser')) {
            setphotoState('mobile')
        }
    }, [])

    const [pridedUserList, setPridedUserList] = useState<any[]>([])

    const [totalUsers, setTotalUsers] = useState({
        withBiometry: 0,
        withoutBiometry: 0
    })

    const getAllUsers = () => {
        setLoading(true)


        fetch(`${BASE.COMPANY.url}${BASE.COMPANY.listAll}/${companyId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            }
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoading(false)
                setPridedUserList(response)

            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }



    const sendEmail = (data) => {
        setLoading(true)


        fetch(`${BASE.api_user.base_url}${BASE.api_user.send_email}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            },
            body: JSON.stringify({
                name: data.name.replace(/^\s+|\s+$/g, ''),
                email: data.email.replace(/^\s+|\s+$/g, ''),
                token: data.token,
                subject: 'Acesso Administrativo Botafogo',
                template: 'bepass-user-creation',
                login: data.email.replace(/^\s+|\s+$/g, ''),
                password: data.password,
                companyUrl: 'https://credenciados-botafogo.bepass.com.br/',
                companyName: 'Botafogo',
            })
        })
            .then((resg) => resg.json())
            .then((response) => {
                setShow(true)
                console.log(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const deleteUser = (userId) => {

        if (confirm("Confirmar exclusão?") == false) {
        return false
        }
        setLoading(true)


        fetch(`${BASE.api.base_url}${BASE.api.access_edit}/${userId}`, {
            method: 'DELETE',
            headers: {
                Authorization: CONFIG.API_KEY
            }
        })
            .then((resg) => resg.json())
            .then((response) => {
                window.location.reload()
            })
            .catch((error) => {
                console.log(error)
            })
    }
    useEffect(() => {
        getAllUsers()
    }, [])


    return (
        <>
            <h2 className='table-title'>Cadastro de acessos</h2>
            <Row>
                <Col xs={12} lg={9}>
                <Table responsive hover>
        <thead className="table-people-header">
            <tr>
                <th scope="col">
                    Dados
                </th>
                <th scope="col">
                    E-mail
                </th>
                <th colSpan={2} scope="col">
                    Action
                </th>
            </tr>
        </thead>
        <tbody className='table-people-data'>

                                    {pridedUserList &&
                                        pridedUserList.map((user: any, index) => (

                                            user.recoveryKey === 'admin' ? (<></>) : (

                                                <tr key={index} className="unread" >

                                                <td scope="row" className="table-people-info">
                                                    <img src={faceOK} alt={user.name} />
                                                    <div>
                                                        <h3>{user.name}</h3>
                                                        <h4>N/A</h4>
                                                    </div>  
                                                </td>

                                                <td>
                                                    <h6 className="mb-1">{user.login}</h6>
                                                </td>
                                                
                                                <td>
                                                <ul className="group-action">
                                                  <li>
                                                      <span className="sidebar-icon"><AiOutlineForm/></span>
                                                  </li>
                                                  <li>
                                                      <span className="no-icon sidebar-icon">Resetar Senha</span>
                                                  </li>
                                                  <li>
                                                      <span className="sidebar-icon icon-red"><AiOutlineMinusCircle/></span>
                                                  </li>
                                                </ul>
                                                </td>
                                                   
                                                </tr>
                                            )

                                        ))}
                                </tbody>
                            </Table>
                     

                </Col>

                <Col xs={12} lg={3}>
                  <UserCreateAdmin />
                </Col>
            </Row>
            <ToastContainer containerPosition="fixed" className="p-3 toast-me" position={'bottom-end'}>
                <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide bg='info'>
                    <Toast.Body>E-Mail enviado</Toast.Body>
                </Toast>
            </ToastContainer>
        </>
    )
}

export default CreateAdminUser
