import React, { Component, useCallback, useEffect, useState } from 'react'
import { Row, Col, Card, Tab, Tabs, Table, ProgressBar, Modal, Button, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'



import { PiMegaphoneLight } from "react-icons/pi";
import { GoChevronDown } from "react-icons/go";
import { PiMagnifyingGlassLight } from "react-icons/pi";
import { LiaFileDownloadSolid } from "react-icons/lia";
import { LiaTimesSolid } from "react-icons/lia";
import { TbDoorEnter } from "react-icons/tb";
import { BsPen } from "react-icons/bs";
import { AiOutlineEye } from "react-icons/ai";
import { TiPen } from "react-icons/ti";
import { MdAddBusiness } from "react-icons/md";
import { FaTimes } from "react-icons/fa";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { SupplierUsersCountCard } from '../components/SupplierUsersCountCard'
import { FaUsersViewfinder } from "react-icons/fa6";
import { MdCompareArrows } from "react-icons/md";


import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import { UserCreate } from '../components/userCreate'

import DatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'

import { useForm } from 'react-hook-form'
import { EventCreate } from './createEvent'
import EventGroupCreationWizard from './eventGroupCreationWizard'
import { EventSnippetDetails } from './eventSnipperDetails';
import { EventSnippetDetailsProvider } from './eventSnipperDetailsProvider';


function EventsSupplier() {
    const supplierId = sessionStorage.getItem('company')

    // if (auth !== 'true') {
    //   window.location.href = '/'
    // }

    // const [userList, setUserList] = useState<any[]>([])

    const [supplierdata, setSupplierdata] = useState<any>({})

    const dateConvert = (date: any) => {
        const startDateTime = new Date(date)

        const options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        }

        setEventDataTransformed({
          dataTransformDay: startDateTime.getDate().toString().padStart(2, '0'),
          dataTransformMonth: new Intl.DateTimeFormat("pt-BR", { month: "short"}).format(startDateTime),
          dataTransformTime: `${startDateTime.getHours()}:${startDateTime.getMinutes().toString().padStart(2, '0')}`,
        })
        return `${startDateTime.toLocaleDateString(
            'pt-br'
        )} ${startDateTime.getHours()}:${startDateTime.getMinutes().toString().padStart(2, '0')}`
    }

    const snippetDateConvert = (date: any) => {
      const startDateTime = new Date(date)

      const options = {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
      }

      return {
        dataTransformDay: startDateTime.getDate().toString().padStart(2, '0'),
        dataTransformMonth: new Intl.DateTimeFormat("pt-BR", { month: "short"}).format(startDateTime),
        dataTransformTime: `${startDateTime.getHours()}:${startDateTime.getMinutes().toString().padStart(2, '0')}`,
      }
  }

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        clearErrors,
        getValues,
        reset
    } = useForm()


    const [disableSign, setDisableSign] = useState(false)



    const userSyncProgress = (partialSync: any, totalSync: any) => {
        return Math.round((totalSync / partialSync) * 100);
    }

    const [isLoading, setLoading] = useState(false)

    const [totalUsers, setTotalUsers] = useState({
        withBiometry: 0,
        withoutBiometry: 0
    })

    const [show, setShow] = useState(false);


    const [eventList, setEventList] = useState<any>([]);



    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [showOrg, setShowOrg] = useState(false);

    const handleShowOrg = () => setShowOrg(true)
    const handleCloseOrg = () => setShowOrg(false)


    const [showConfirm, setShowConfirm] = useState(false);

    const handleShowConfirm = () => setShowConfirm(true)
    const handleCloseConfirm = () => setShowConfirm(false)




    const [eventData, setEventData] = useState<any>({})
    const [eventDataTransformed, setEventDataTransformed] = useState<any>({})
    const [userBiometryCount, setUserBiometryCount] = useState<any>({})

    const getNextEvent = () => {
      fetch(`${BASE.EVENT.url}${BASE.EVENT.next_event_info}`, {
        method: 'GET',
      })
        .then((resg) => resg.json())
        .then((response) => {
          setEventData(response.event)
          dateConvert(response.event.startDateTime)
          getEventUsers(response.event.eventCode)
        })
        .catch((error) => {
          console.log(error)
        })
    }

    const getEventUsers = (eventId) => {
      fetch(`${BASE.EVENT.url}${BASE.EVENT.event_users_count}/${eventId}`, {
        method: 'GET',
      })
        .then((resg) => resg.json())
        .then((response) => {
          setUserBiometryCount(response)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  
    const getAllEvents = () => {
      fetch(`${BASE.EVENT.url}${BASE.EVENT.all_events}/${supplierId}`, {
        method: 'GET',
      })
        .then((resg) => resg.json())
        .then((response) => {
          setEventList(response)
        })
        .catch((error) => {
          console.log(error)
        })
    }


    useEffect(() => {
      getNextEvent()
      getAllEvents()
    }, [])


    const dateFormatter = (dateTime:string)=>{

      const options:any = {
        year: '2-digit',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      };
  
      const oldDate = new Date(dateTime)
      return oldDate.toLocaleDateString('pt-BR', options).split(',').join(' ')
    }









    const [currentEventEdit, setCurrentEventEdit] = useState<any>({})
    const [eventDue, setEventDue] = useState<any>()

    
      const editEvent = (data: any) => {

        if(eventDue){
          data.startDateTime = new Date(eventDue).toISOString()
        }

        fetch(`${BASE.EVENT.url}${BASE.EVENT.info}/${currentEventEdit.eventCode}`, {
            method: 'Put',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BASE.api.api_key
            },
            body: JSON.stringify(data)
        })
            .then((response) => {
                window.location.reload();
            })
            .catch((error) => {
                console.log(error)
            })
      }

      const excludeEvent = (eventId: any) => {



        fetch(`${BASE.EVENT.url}${BASE.EVENT.info}/${eventId}`, {
            method: 'Put',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BASE.api.api_key
            },
            body: JSON.stringify({
              status: 'deleted',
              isActive: false
            })
        })
            .then((response) => {
                window.location.reload();
            })
            .catch((error) => {
                console.log(error)
            })
      }
    
      
      const modalOpen = (item:any) => {
        setCurrentEventEdit({})
      
        setTimeout(() => {
          setCurrentEventEdit(item)
          handleShow()
        }, 300);
      }
      
    
    return (
        <>

{/* <h2 className='table-title'>Evento Ativo</h2> */}

{eventData && (


<div className="main-event inside-event">
<div className="event-header">
            <div className="event-data">
              <h1 className="event-name">{eventData?.title}</h1>
              <h2 className="event-date">{dateFormatter(eventData?.startDateTime)}</h2>
            </div>
          </div>
          <div className="event-content">
  
          {/* <div className="event-ticket-total event-header-content-box">
              <div className="event-header-content-box-main">
                Pessoas Convocadas
                <div className='event-ticket-counter'>9999</div>
              </div>
              <div className='event-ticket-button'>
                    visualizar
                    <div className="event-icon">
                      <AiOutlineUsergroupAdd/>
                    </div>
                  </div>
            </div> */}


            <div className="event-ticket-total event-header-content-box">
              <div className="event-header-content-box-main">
              Pessoas Convocadas
                <div className='event-ticket-counter'>{userBiometryCount.totalUsers}</div>
              </div>

              <Link to={`/provider/events/${eventData?.eventCode}/${supplierId}`} className="event-ticket-button">
                Convocar
                <div className="event-icon">
                  <AiOutlineUsergroupAdd/>
                </div>
              </Link>
            </div>


            
 

            <div className="event-ticket-total event-header-content-box">
              <div className="event-header-content-box-main">
                Total de Entradas
                <div className='event-ticket-counter'>0000</div>
              </div>
            </div>

   

          </div>

</div>
)}
{/* <div className="event-action-top">
  
  <ul className="event-action-list">
    <li className='btn btn-default'>
      <i className='custom-icon'>
        <TiPen/>
      </i>
      Editar
    </li>
    <li className='btn btn-default'>
      <i className='custom-icon'>
        <MdAddBusiness/>
      </i>
      Empresas
    </li>
    <li className='btn btn-default'>
      <i className='custom-icon'>
        <FaTimes/>
      </i>
      Excluir
    </li>
  </ul>
<div onClick={() => window.location.href = `/events/providers/${eventData.eventCode}`} className="square-button call-buttom rounded-sm">
        <i className='custom-icon'>
          <AiOutlineEye/>
        </i>
        <span>Visualizar</span>
    </div>
  <div className="event-action-top-info">
    <div className="square-button rounded-sm">
      <span className="event-info-month">
      {eventDataTransformed.dataTransformMonth}
      </span>
      <span className="event-info-date">
        {eventDataTransformed.dataTransformDay}
      </span>
    </div>
    <div className="square-button event-title rounded-sm">
      <span className="event-info-time">
      {eventDataTransformed.dataTransformTime}
      </span>
      <span className="event-info-title">
        {eventData.title}
      </span>

</div>
</div> </div>
 */}

            <h2 className='table-title'>Próximos Eventos</h2>
            <Row>




            <Col md={6} xl={8}>
  {eventList.map((item) => (
    <EventSnippetDetailsProvider
    key={item.id}
    eventCode={item.id} eventDataTransformed={snippetDateConvert(item.startDateTime)} eventTitle={item.name}
    eventStatus={item.status} />
  ))}
</Col>

         
</Row>



<Modal show={show} onHide={handleClose}>

<Modal.Body>
<Form onSubmit={handleSubmit(editEvent)}>


            <Form.Label>Jogo:</Form.Label>
            <Form.Control placeholder="Jogo: "
            {...register('eventName')}
            defaultValue={currentEventEdit.title}
            className='mb-3'
            />

            <Form.Label>Data / hora</Form.Label>
            <DatePicker
                className="form-control"
                locale="pt-br"
                showTimeInput={true}
                timeInputLabel='Hora'
                dateFormat="dd/MM/yyyy : HH:mm"
                selected={eventDue}
                onChange={(date: Date) => setEventDue(date)}
            />
            {errors.dueDate && (
                <span className="label-error">
                    Você precisa informar uma data válida
                </span>
            )}


            <Form.Label>Status</Form.Label>
            <Form.Select
              {...register('status')}
              defaultValue={currentEventEdit.status}
              className='mb-3'
            >
              <option value={'active'}>Ativo</option>
              <option value={'idle'}>Inativo</option>
            </Form.Select>
            {errors.email && (
              <span className="label-error">
              </span>
            )}

            <Form.Label>Logo Time Visitante:</Form.Label>
            <Form.Control
              type="file"
              placeholder="Logo"
              className='mb-3'
            />
   
          <Form.Group>
            <Button
              disabled={disableSign}
              type="submit"
              className="mb-0 w-100"
              onClick={() => clearErrors()}
            >
              Salvar
            </Button>
          </Form.Group>

  </Form>

</Modal.Body>

  </Modal>

        </>
    )
}

export default EventsSupplier
